import React, { Suspense, lazy, useState } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from '@utils'
import Seo from "@components/seo";
import Layout from "@layout";
import Header from '@layout/header/layout-01';
import StickyMenu from "@containers/sticky-menu";
import PageHeader from "@containers/page-header/layout-01";
import QuoteForm from "@containers/translation-quote-form";
import FeatureGrid from "@components/feature-grid";
import IntroAreaOne from "@components/introarea/layout-4";
import CtaArea from "@containers/cta/layout-02";
import CaseStudy from "@containers/global/case-study/layout-01";
import BoxSection from "@components/BoxSection/layout-three/layout-3";
import KeyBenifits from '@containers/ai-voiceover/key-benifits'
import { caseStudiesData } from "@constants";
import IntroAreaReverse from "@components/introareareverse/layout-5";
import IntroArea from "@components/introarea/layout-6";
import ContactArea from "@containers/contact-us";
import UseScroll from "@containers/scroll";
const Footer = lazy(() => import("@layout/footer/layout-01"))
const AiVoiceOver = ({ location, data }) => {
  const [showRestComponents, setShowRestComponents] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);
  const content = normalizedData(data?.page.content || []);
  UseScroll((scrollTop) => {
    if (scrollTop > 20) {
      setShowRestComponents(true);
    } else {
      setShowRestComponents(false);
    }
  }, setIsMobile);
  return (
    <Layout location={location}>
      <Seo
        title="AI Voiceover Services"
        description="Unlock the power of AI voiceover with Andovar, where advanced AI and machine learning transform scripted text into captivating audio."
      />
      <Header
        data={{
          ...globalContent['menu'],
          ...data.site.siteMetadata,
          isMobile: isMobile
        }}
      />
      {
        !isMobile && <StickyMenu data={content["sticky-menu-data"]} setShowRestComponents={setShowRestComponents} showRestComponents={showRestComponents} />
      }
      <main className="site-wrapper-reveal">
        <PageHeader data={content["ai-voiceover-page-header"]} />
        <QuoteForm />
        {
          (!isMobile || (isMobile && showRestComponents)) && <>
            <FeatureGrid data={content["ai-voiceover-features"]} />
            <IntroAreaOne layout={2} data={content["ai-voiceover-data"]} />
          </>
        }
        {
          showRestComponents && <>
            <IntroArea layout={3} data={content["why-ai-voiceover-body"]} />
            <CtaArea data={content["ai-voiceover-cta-data"]} />
            <KeyBenifits data={content['key-benifits']} />
            <IntroAreaReverse layout={2} data={content["customization-voiceover-body"]} />
            <BoxSection layout={5} data={content["empowering-section-body"]} />
            <CaseStudy layout={3} data={caseStudiesData} />
            <ContactArea Layout={2} />
          </>
        }
      </main>
      {
        showRestComponents && <Suspense fallback={<div></div>}>
          <Footer data={{ ...data.site.siteMetadata }} />
        </Suspense>
      }
    </Layout>
  );
}

export const query = graphql`
  query AiVoiceOver {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(
      title: { eq: "ai-voiceover" }
      pageType: { eq: "innerpage" }
    ) {
      content {
        ...PageContent
      }
    }
  }
`;

AiVoiceOver.propTypes = {
  location: PropTypes.object,
  data: PropTypes.shape({
    allGeneral: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({}),
      }),
    }),
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default AiVoiceOver;
